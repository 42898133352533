import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./AppStoreOnePartners.scss";

// FIREBASE
import { useCollection } from "../../../../firebase files/hooks/useCollection";

const AppStoreOnePartners = ({ id }) => {
  const { documents: partners, error: partnersError } = useCollection("media", ["category", "==", "partners"]);
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (window.$ && window.$.fn.owlCarousel && carouselRef.current) {
      const owl = window.$(carouselRef.current).owlCarousel({
        items: 3,
        loop: true,
        margin: 20,
        autoplay: true,
        autoplayTimeout: 2000,
        center: true,
        autoplayHoverPause: true,
        onChanged: (event) => {
          setCurrentIndex(event.item.index); // Update current index on carousel change
        },
        responsive: {
          0: { items: 2 },
          600: { items: 2 },
          1000: { items: 3 },
          1200: { items: 4 },
        },
      });

      return () => {
        owl.trigger("destroy.owl.carousel");
      };
    }
  }, [partners]);

  return (
    <div id={id} className="app-store-one-partners">
      <div className="app-store-one-partners-content row">
        {/* Left Section */}
        <div className="app-store-one-partners-left col-12 col-md-4">
          <h2>Join Our Trusted Partner Network</h2>
          <Link to="/products">
            <button className="app-store-one-button btn_border">Start your journey</button>
          </Link>
        </div>

        {/* Right Section */}
        <div className="app-store-one-partners-right col-12 col-md-8">
          <div ref={carouselRef} className="app-store-one-row owl-carousel">
            {partners &&
              partners.concat(partners).map((partner, index) => (
                <div
                  key={index}
                  className={`app-store-one-item ${index === currentIndex ? "center" : ""}`}
                >
                  <div className="app-store-one-image-wrapper">
                    <img
                      src={partner.mediaUrl}
                      alt={`Partner ${index + 1}`}
                      className={`app-store-one-image ${index === currentIndex ? "color" : "grayscale"}`}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppStoreOnePartners;
