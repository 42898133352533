import React from 'react'
import "../../appStoreOneCss/AppStoreOneFont.scss"
import AppStoreOneBanners from "../../appStoreOneComponents/AppStoreOneBanners";
import BusinessThreeBanners from '../../../../business/businessThree/businessThreeComponents/BusinessThreeBanners';
import AppStoreOneHero from '../../appStoreOneComponents/AppStoreOneHero';
import AppStoreOneWhyUs from '../../appStoreOneComponents/AppStoreOneWhyUs';
import AppStoreOneVideo from '../../appStoreOneComponents/AppStoreOneVideo';
import AppStoreOneDownloadApp from '../../appStoreOneComponents/AppStoreOneDownloadApp';
import AppStoreOneOurApps from '../../appStoreOneComponents/AppStoreOneOurApps';
import AppStoreOneMoreMenus from '../../appStoreOneComponents/AppStoreOneMoreMenus';
import AppStoreOnePrivacy from '../../appStoreOneComponents/AppStoreOnePrivacy';
import AppStoreOneCounter from '../../appStoreOneComponents/AppStoreOneCounter';
import AppStoreOneFaq from '../../appStoreOneComponents/AppStoreOneFaq';
import AppStoreOnePartners from '../../appStoreOneComponents/AppStoreOnePartners';
const AppStoreOneHome = () => {
  return (
    <div>
      {/* <AppStoreOneBanners/> */}
      <BusinessThreeBanners />
      {/* <div className='large_margin_div'></div> */}
      <AppStoreOnePartners />
      {/* <div className='large_margin_div'></div> */}
      <AppStoreOneHero />
      {/* <div className='large_margin_div'></div> */}
      <AppStoreOneCounter />
      <div className='large_margin_div'></div>
      <AppStoreOneOurApps />
      <div className='large_margin_div'></div>
      <AppStoreOneVideo />
      <AppStoreOneFaq />
      <div className='large_margin_div'></div>
      <AppStoreOneWhyUs />
      <div className='large_margin_div'></div>
      <div className='large_padding_div'></div>
      <div className='small_padding_div'></div>
      <AppStoreOneDownloadApp />
      <div className='large_margin_div'></div>
      <AppStoreOneMoreMenus />
      <div className='large_margin_div'></div>
      <div className='large_padding_div'></div>
      <AppStoreOnePrivacy />
    </div>
  )
}

export default AppStoreOneHome
