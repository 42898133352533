import React from "react";
import "./AppStoreOneWhyUs.scss";

const AppStoreOneWhyUs = () => {
  return (
    <div className="container">
      <div className="app-store-one-why-us-container row">
        <div className="app-store-one-why-us-content col-12 col-md-6">
          <h2 className="app-store-one-why-us-title app-store-one-heading">// Why Choose Us?</h2>
          <p className="app-store-one-why-us-description app-store-one-sub-heading">
            We bring years of experience and innovation to provide reliable solutions for your digital needs. Here’s why we stand out:
          </p>
          <ul className="app-store-one-why-us-features">
            <li>
              <div className="app-store-one-why-us-icon">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="var(--grey-dark)"><path d="M0-240v-63q0-43 44-70t116-27q13 0 25 .5t23 2.5q-14 21-21 44t-7 48v65H0Zm240 0v-65q0-32 17.5-58.5T307-410q32-20 76.5-30t96.5-10q53 0 97.5 10t76.5 30q32 20 49 46.5t17 58.5v65H240Zm540 0v-65q0-26-6.5-49T754-397q11-2 22.5-2.5t23.5-.5q72 0 116 26.5t44 70.5v63H780Zm-455-80h311q-10-20-55.5-35T480-370q-55 0-100.5 15T325-320ZM160-440q-33 0-56.5-23.5T80-520q0-34 23.5-57t56.5-23q34 0 57 23t23 57q0 33-23 56.5T160-440Zm640 0q-33 0-56.5-23.5T720-520q0-34 23.5-57t56.5-23q34 0 57 23t23 57q0 33-23 56.5T800-440Zm-320-40q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-600q0 50-34.5 85T480-480Zm0-80q17 0 28.5-11.5T520-600q0-17-11.5-28.5T480-640q-17 0-28.5 11.5T440-600q0 17 11.5 28.5T480-560Zm1 240Zm-1-280Z" /></svg>            </div>
              <div className="app-store-one-why-us-text">
                <h4>Experienced Team</h4>
                <p className="app-store-one-sub-heading">Global expertise in app development and website solutions.</p>
              </div>
            </li>
            <li>
              <div className="app-store-one-why-us-icon">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="var(--grey-dark)"><path d="M40-480q0-92 34.5-172T169-791.5q60-59.5 140-94T480-920q91 0 171 34.5t140 94Q851-732 885.5-652T920-480h-80q0-75-28.5-140.5T734-735q-49-49-114.5-77T480-840q-74 0-139.5 28T226-735q-49 49-77.5 114.5T120-480H40Zm160 0q0-118 82-199t198-81q116 0 198 81t82 199h-80q0-83-58.5-141.5T480-680q-83 0-141.5 58.5T280-480h-80ZM360-64l-56-56 136-136v-132q-27-12-43.5-37T380-480q0-42 29-71t71-29q42 0 71 29t29 71q0 30-16.5 55T520-388v132l136 136-56 56-120-120L360-64Z" /></svg>            </div>
              <div className="app-store-one-why-us-text">
                <h4>Technical Skills</h4>
                <p className="app-store-one-sub-heading">Cutting-edge tools and technologies for your business growth.</p>
              </div>
            </li>
            <li>
              <div className="app-store-one-why-us-icon">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="var(--grey-dark)"><path d="M440-120v-80h320v-284q0-117-81.5-198.5T480-764q-117 0-198.5 81.5T200-484v244h-40q-33 0-56.5-23.5T80-320v-80q0-21 10.5-39.5T120-469l3-53q8-68 39.5-126t79-101q47.5-43 109-67T480-840q68 0 129 24t109 66.5Q766-707 797-649t40 126l3 52q19 9 29.5 27t10.5 38v92q0 20-10.5 38T840-249v49q0 33-23.5 56.5T760-120H440Zm-80-280q-17 0-28.5-11.5T320-440q0-17 11.5-28.5T360-480q17 0 28.5 11.5T400-440q0 17-11.5 28.5T360-400Zm240 0q-17 0-28.5-11.5T560-440q0-17 11.5-28.5T600-480q17 0 28.5 11.5T640-440q0 17-11.5 28.5T600-400Zm-359-62q-7-106 64-182t177-76q89 0 156.5 56.5T720-519q-91-1-167.5-49T435-698q-16 80-67.5 142.5T241-462Z" /></svg>
              </div>
              <div className="app-store-one-why-us-text">
                <h4>Global Support</h4>
                <p className="app-store-one-sub-heading">24/7 assistance to ensure seamless operations and solutions.</p>
              </div>
            </li>
          </ul>
          <button className="app-store-one-why-us-button">Contact Now</button>
        </div>

        <div className="app-store-one-why-us-images col-12 col-md-6">
          <img
            className="app-store-one-why-us-image app-store-one-why-us-image-1"
            src="https://th.bing.com/th/id/OIP.ZTsdWf9bmWsM3Npsw2uu4QHaFj?rs=1&pid=ImgDetMain"
            alt="Why Choose Us"
          />
          <img
            className="app-store-one-why-us-image app-store-one-why-us-image-2"
            src="https://uniquekiosk.com/wp-content/uploads/2020/04/02-10-600x600.jpg"
            alt="Why Choose Us"
          />
          <img
            className="app-store-one-why-us-image app-store-one-why-us-image-3"
            src="https://conceptsandsolutions.in/virtualstore/img/2.jpg"
            alt="Why Choose Us"
          />
        </div>
      </div>
    </div>
  );
};

export default AppStoreOneWhyUs;
