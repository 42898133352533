import React, { useEffect, useRef, useState } from "react";
import "./AppStoreOneOurApps.scss";
import { Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { Link } from "react-router-dom";

// FIREBASE 
import { useCollection } from "../../../../firebase files/hooks/useCollection";

const AppStoreOneOurApps = ({ id }) => {
    const [activeTab, setActiveTab] = useState(0); // Default to the first category
    const [slideClass, setSlideClass] = useState("slide-in");
    const [filteredProducts, setFilteredProducts] = useState([]);
    const categoryContainerRef = useRef(null); // Ref for the category container

    // Fetch active product categories
    const { documents: productsCategories, error: productsCategoriesError } =
        useCollection("m_categories", ["status", "==", "active"]);

    // Fetch all products
    const { documents: allProducts, error: productsError } =
        useCollection("products", ["status", "==", "active"]);

    // Filter products by selected category
    const handleTabClick = (index, categoryName) => {
        if (index !== activeTab) {
            setSlideClass("slide-out");
            setTimeout(() => {
                setActiveTab(index);
                setSlideClass("slide-in");
            }, 500); // Matches the duration of the transition

            // Filter products by selected category name
            const productsForCategory = allProducts.filter(
                (product) => product.category === categoryName // Match category by name
            );
            setFilteredProducts(productsForCategory);
        }
    };

    // Default filter for the first category when the component loads
    useEffect(() => {
        if (productsCategories?.length > 0 && allProducts?.length > 0) {
            const defaultCategoryName = productsCategories[0]?.category; // Get the name of the first category
            const productsForCategory = allProducts.filter(
                (product) => product.category === defaultCategoryName // Filter products by default category name
            );
            setFilteredProducts(productsForCategory);
            setActiveTab(0); // Ensure first category is selected
        }
    }, [productsCategories, allProducts]);

    useEffect(() => {
        if (productsCategoriesError) console.error("Categories Error:", productsCategoriesError);
        if (productsError) console.error("Products Error:", productsError);
    }, [productsCategoriesError, productsError]);

    const scrollCategories = (direction) => {
        console.log(direction, "scroll cliked");

        if (categoryContainerRef.current) {
            const scrollAmount = 200; // Adjust as needed
            categoryContainerRef.current.scrollLeft += direction === "left" ? -scrollAmount : scrollAmount;
        }
    };
    return (
        <>
            <div id={id}></div>
            <div className="container">
                <h1 className="app-store-one-heading">
   // Explore the best apps<span style={{ color: "var(--logo-primary-color)" }}> by category</span>
                </h1>
                <br />
                <div className="d-flex align-items-center justify-content-between app-store-one-services-top-selector">

                    <div className="app-store-one-arrow-left" onClick={() => scrollCategories("left")}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="black">
                            <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
                        </svg>
                    </div>
                    <div
                        ref={categoryContainerRef}
                        className="app-store-one-categories-container"
                    >
                        {productsCategories &&
                            productsCategories.map((category, index) => (
                                <div
                                    key={category.id || index}
                                    onClick={() => handleTabClick(index, category.category)} // Pass category name
                                    className={`app-store-one-service-tab ${activeTab === index ? "active" : ""}`}
                                >
                                    <img
                                        src={category.categoryImgUrl || "default-icon-url"}
                                        alt={category.category || "category"}
                                        className="app-store-one-services-category-icon"
                                    />
                                    &nbsp;
                                    {category.category || "category Name"}
                                </div>
                            ))}
                    </div>
                    <div className="app-store-one-arrow-right" onClick={() => scrollCategories("right")}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="black">
                            <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
                        </svg>
                    </div>
                </div>

                <div className="app-store-one-service-selected-service">
                    <div className={`app-store-one-service-selected-slider row gap-4 ${slideClass}`}>
                        {filteredProducts.length > 0 ? (
                            filteredProducts.map((product) => (
                                <div className="app-store-one-services-slider-item col-md-4 col-lg-4 col-12" key={product.id}>
                                    <Link to={`/product-details/${product?.id}`}>
                                        <div className="d-flex">
                                            <img
                                                src={product.images[0]}
                                                alt={product.name}
                                                className="app-store-one-services-slider-product-image"
                                            />
                                            &nbsp;
                                            <div className="d-flex flex-column justify-content-center">
                                                <p className="app-store-one-services-slider-product-name">{product.name}</p>
                                                <Rating
                                                    value={parseFloat(product.rating)}
                                                    precision={0.5}
                                                    readOnly
                                                    emptyIcon={
                                                        <StarIcon
                                                            style={{ opacity: 0.45 }}
                                                            fontSize="inherit"
                                                        />
                                                    }
                                                />
                                                <p className="app-store-one-services-slider-product-price">{product.price}/-</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        ) : (
                            <p>No active products available in this category.</p>
                        )}
                    </div>
                </div>

                <div className="app-store-one-dots-container">
                    {productsCategories &&
                        productsCategories.map((_, index) => (
                            <div
                                key={index}
                                className={`app-store-one-dot ${activeTab === index ? "active" : ""}`}
                            ></div>
                        ))}
                </div>
            </div>
            <div className="large_padding_div"></div>
        </>
    );
};

export default AppStoreOneOurApps;
