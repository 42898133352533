import React, { useState, useEffect, useRef } from "react";
import "./AppStoreOneFaq.scss";
import { useCollection } from "../../../../firebase files/hooks/useCollection";
import { Link } from "react-router-dom";

const AppStoreOneFaq = ({ id }) => {
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [displayAnswer, setDisplayAnswer] = useState("");
  const [showMore, setShowMore] = useState(false);
  const typingRef = useRef(null);
  const headingRef = useRef(null);

  // Fetch FAQ data from Firestore
  const { documents: faqs, error: faqsError } = useCollection("media", ["category", "==", "faq"]);

  const preprocessAnswer = (answer) => {
    return answer
      .split("\n")
      .map((line) => (line.trim().length > 0 ? " " + line : line))
      .join("\n");
  };

  const handleFaqClick = (index) => {
    if (index !== selectedFaq) {
      setSelectedFaq(index);
      setDisplayAnswer("");
    }
  };

  useEffect(() => {
    if (selectedFaq !== null) {
      const answer = preprocessAnswer(faqs[selectedFaq]?.longDescription || "");
      let i = 0;

      if (typingRef.current) {
        clearTimeout(typingRef.current);
      }

      const typeAnswer = () => {
        if (i < answer.length) {
          setDisplayAnswer((prev) => prev + answer.charAt(i));
          i++;
          typingRef.current = setTimeout(typeAnswer, 10);
        }
      };

      typeAnswer();
    }
  }, [selectedFaq, faqs]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          } else {
            entry.target.classList.remove("animate");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, []);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const faqToShow = showMore ? faqs : faqs&&faqs.slice(0, 5);

  return (
    <div className="app-store-one-faq-container-parent" id={id}>
      <div className="small_padding_div"></div>
      <div ref={headingRef} className="container">
        <h3 className="app-store-one-heading">
          <span style={{ color: "var(--logo-primary-color)", padding: "0px" }}>// FAQ</span> What You Need to Know
        </h3>
      </div>
      <div className="small_padding_div"></div>

      <div className="container app-store-one-faq-container">
        <div className="row">
          <div className="col-12 col-md-6 app-store-one-faq-questions">
            {faqToShow && faqToShow.map((faq, index) => (
              <div
                key={index}
                onClick={() => handleFaqClick(index)}
                className={`app-store-one-faq-question ${selectedFaq === index ? "active" : ""}`}
              >
                {faq.heading}
                <span className="material-symbols-outlined">arrow_forward_ios</span>
              </div>
            ))}
          </div>
          <div className="col-12 col-md-6 app-store-one-faq-answer">
            {selectedFaq == null ? (
              <div className="app-store-one-faq-answer-content">
                <h5>
                  Discover
                  <span style={{ fontWeight: "700" }}>
                    {" "} Premium Apps & Templates{" "}
                  </span>
                  for Your Business,
                  <span style={{ fontWeight: "700" }}> NOW AVAILABLE </span>
                </h5>
                <hr />
                Professionally Designed Templates | Ready-to-Use Apps
                <br />
                Upto 25% OFF On Your First Purchase
                <hr />
                <Link to="/faq">
                <button className="btn_fill">Explore More</button></Link>
              </div>
            ) : (
              <div className="app-store-one-faq-answer-content">
                {displayAnswer}
              </div>
            )}
          </div>
        </div>
        {faqs && faqs.length > 5 && (
         <Link to="/faq">
           <button className="btn_fill" onClick={handleShowMore}>
            {showMore ? "Show Less" : "Show More"}
          </button>
         </Link>
        )}
      </div>
      <div className="small_padding_div"></div>
    </div>
  );
};

export default AppStoreOneFaq;
