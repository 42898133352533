import React, { useState, useEffect } from "react";
import "./AppStoreOneHero.scss";

const AppStoreOneHero = () => {
    const phrases = ["Digital Future", "Next Generation", "Business Growth"];
    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
    const [currentText, setCurrentText] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [typingSpeed, setTypingSpeed] = useState(100); // Typing speed in ms

    const trustedByImages = [
        "https://e7.pngegg.com/pngimages/930/269/png-clipart-firebase-software-development-kit-application-programming-interface-real-time-database-best-angle-text.png",
        "https://pngimg.com/uploads/github/github_PNG23.png",
        // "https://files.codingninjas.in/article_images/overview-of-google-cloud-cli-0-1660578103.webp",
        "https://2.bp.blogspot.com/-2L3KX_48TEQ/XJ0AIfGqG9I/AAAAAAAADew/tiTNwPkS84Y2lPmM8z6IQadB_TRxETHLwCLcBGAs/s1600/Firebase%2BAuthentication%2B%25282-%2BHorizontal%2BLockup%252C%2BLight%2529.png",
    "https://logos-world.net/wp-content/uploads/2020/10/WordPress-Logo-2003-2008-700x394.png"
    ];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        // Typing animation logic
        const handleTyping = () => {
            const currentPhrase = phrases[currentPhraseIndex];
            if (isDeleting) {
                setCurrentText((prev) => prev.slice(0, -1));
                setTypingSpeed(50);
            } else {
                setCurrentText((prev) => currentPhrase.slice(0, prev.length + 1));
                setTypingSpeed(100);
            }

            if (!isDeleting && currentText === currentPhrase) {
                setIsDeleting(true);
                setTypingSpeed(1000);
            } else if (isDeleting && currentText === "") {
                setIsDeleting(false);
                setCurrentPhraseIndex((prev) => (prev + 1) % phrases.length);
            }
        };

        const timer = setTimeout(handleTyping, typingSpeed);
        return () => clearTimeout(timer);
    }, [currentText, isDeleting, typingSpeed, currentPhraseIndex]);

    useEffect(() => {
        // Trusted by images animation logic
        const imageTimer = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % trustedByImages.length);
        }, 3000); // Change image every 2 seconds
        return () => clearInterval(imageTimer);
    }, [trustedByImages.length]);

    return (
        <div  style={{backgroundColor:"var(--white-color)"}}>
              <div className='large_padding_div'></div>
        <div className="container">
            <div className="app-store-one-hero-container row">
                <div className="app-store-one-hero-content col-12 col-md-6">
                    <h1 className="app-store-one-hero-title app-store-one-heading">
                        Build Your <span className="app-store-one-hero-title-highlight">{currentText}</span>
                    </h1>
                    <p className="app-store-one-hero-description app-store-one-sub-heading">
                        Discover innovative apps and custom-built websites designed to boost your business. From seamless user experiences to cutting-edge technology, we provide solutions tailored for your success.
                    </p>
                    <div className="app-store-one-hero-buttons">
                        <button className="app-store-one-hero-btn app-store-one-hero-btn-primary">Explore Apps</button>
                        <button className="app-store-one-hero-btn app-store-one-hero-btn-secondary">Get in Touch</button>
                    </div>
                    <p className="app-store-one-hero-note">
                        ✦ Start your journey with us today! No upfront fees, no hidden costs.
                    </p>
                    <div className="app-store-one-hero-works-with">
                        <span>Trusted Platform:</span>
                        <div className="app-store-one-hero-icons">
                            {/* Animated image display */}
                            <img
                                src={trustedByImages[currentImageIndex]}
                                alt="Trusted by"
                                className="app-store-one-hero-trusted-by-image"
                            />
                        </div>
                    </div>
                </div>
                <div className="app-store-one-hero-visuals col-12 col-md-6">
                    <img src="/assets/img/app store/used platforms.gif" alt="Hero Visual" />
                </div>
            </div>
        </div>
        <div className='large_padding_div'></div>
        </div>
    );
};

export default AppStoreOneHero;
